<template>
  <v-row no-gutters class="h-100">
    <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center">
      <v-card class="w-100" outlined>
        <v-card-title>
          <h4 class="primary--text">
            {{ $t('addDevice.title') }}
          </h4>
        </v-card-title>
        <v-card-text>
          <v-form ref="loginForm" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :label="$t('addDevice.name')"
                  outlined
                  dense
                  single-line
                  required
                  validate-on-blur
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="px-0 d-flex">
            <v-btn color="primary" min-width="120" outlined @click="$router.push({ name: 'Home' })">
              {{ $t('addDevice.cancel') }}
            </v-btn>
            <v-btn :disabled="submitLoading" color="primary" min-width="120" class="ml-auto" :loading="submitLoading" @click="submitHandle" depressed>
              {{ $t('addDevice.save') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import services from '@/services'

export default {
  name: 'AddDevice',
  data () {
    return {
      name: navigator.platform,
      submitLoading: false,
      valid: true,
      nameRules: [
        v => !!v.trim() || this.$t('validation.nameRequired')
      ]
    }
  },
  methods: {
    async submitHandle () {
      this.$refs.loginForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        const { data } = await services.post('Device/AddDevice', {
          name: this.name
        })
        localStorage.setItem('deviceId', data)
        if (this.$store.getters.isAdmin || this.$store.getters.isCustomerService) {
          await this.$router.push({ name: 'CustomerServiceDashboard' })
        } else {
          await this.$router.push({ name: 'Home' })
        }
      } catch (e) {}
      this.submitLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
